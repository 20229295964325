<template>
  <v-dialog
    :value="true"
    :fullscreen="$vuetify.breakpoint.xsOnly"
    transition="slide-y-reverse-transition"
    max-width="900"
    scrollable
    persistent
    @keydown.esc="goToSuppliersPage"
  >
    <SupplierForm
      :supplier="editedSupplier"
      :errors="supplierValidationErrors"
      :disabled="$store.getters.loading[`put:api/suppliers/${editedSupplier.id}`]"
      @clear:errors="clearSupplierValidationErrors"
      @cancel="goToSuppliersPage"
      @save="onSave"
    />
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import store from '@/store';
import SupplierForm from '@/components/SupplierForm';

export default {
  name: 'EditSupplier',
  components: { SupplierForm },
  computed: mapState('suppliers', [
    'editedSupplier',
    'supplierValidationErrors',
    'suppliersFilterParams',
  ]),

  beforeRouteEnter(to, from, next) {
    store
      .dispatch('suppliers/editSupplier', +to.params.id)
      .then(() => {
        next();
      })
      .catch(() => {
        next({ name: 'suppliers', query: store.state.suppliers.suppliersFilterParams });
      });
  },

  methods: {
    ...mapActions('suppliers', ['updateSupplier', 'clearSupplierValidationErrors']),

    onSave(supplier) {
      this.updateSupplier(supplier).then(() => {
        this.goToSuppliersPage();
      });
    },

    goToSuppliersPage() {
      this.$router.push({ name: 'suppliers', query: this.suppliersFilterParams }).catch(() => {});
    },
  },
};
</script>

<style scoped></style>
