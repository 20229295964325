<template>
  <v-form @submit.prevent="$emit('save', supplier)" autocomplete="off">
    <v-card>
      <v-card-title>
        {{ formTitle }}
      </v-card-title>

      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="supplier.name"
              :error-messages="errors['name']"
              :label="formMixin_getRequiredFieldLabel($t('name'))"
              @input="formMixin_clearErrors('name')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-text-field
              v-model="supplier.email"
              :error-messages="errors['email']"
              :label="formMixin_getRequiredFieldLabel($t('email'))"
              @input="formMixin_clearErrors('email')"
            />
          </v-col>

          <v-col cols="12" sm="6">
            <v-select
              v-model="supplier.responsible_for"
              :error-messages="errors['responsible_for']"
              :label="formMixin_getRequiredFieldLabel($t('responsible_for'))"
              :items="responsibleItems"
              item-text="label"
              item-value="value"
              @input="formMixin_clearErrors('responsible_for')"
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions>
        <span v-if="!supplier.id" class="text-subtitle-2 ml-3"> * {{ $t('must_be_filled') }}</span>

        <v-spacer />

        <v-btn text color="primary" @click="$emit('cancel')">{{ $t('cancel') }}</v-btn>

        <v-btn :disabled="disabled" :loading="disabled" color="primary" text type="submit">
          {{ $t('save') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import formMixin from '@/mixins/form-mixin';

export default {
  name: 'SupplierForm',

  mixins: [formMixin],

  props: {
    supplier: {
      type: Object,
      required: true,
    },
    errors: {
      type: Object,
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    formTitle() {
      return this.$t(this.supplier.id ? 'edit_supplier' : 'new_supplier');
    },

    responsibleItems() {
      return [
        {
          label: this.$t('veneer'),
          value: 'veneers',
        },
        {
          label: this.$t('handles'),
          value: 'handles',
        },
        {
          label: this.$t('glass'),
          value: 'glass',
        },
        {
          label: this.$t('paint'),
          value: 'paint',
        },
      ];
    },
  },
};
</script>

<style scoped></style>
